import React from "react";
import styled from "styled-components";
import { DownloadFileButton } from "@flow/components";

const FileTab = ({ flow }) => {
    const files = flow.data.files;
    const components = [];
    for (const key in files) {
        const file = files[key];
        components.push(
            <Container>
                <File> {file.filename} <DownloadFileButton fileId={file.storeID} ></DownloadFileButton> </File>
            </Container>
        );
    }
    return (<div>
        {components}
    </div>
    );
}
const Container = styled.div`
    margin: 10px;
`;

const File = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: baseline;
    border-bottom: 1px solid #efefef;
    margin: 10px;
`;


export default FileTab;

