import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Formik, ErrorMessage } from "formik";

import { PrimaryProgressButton, SecondaryButton } from "@flow/buttons";
import { Colors, Headings } from "@flow/style";
import { RadioButtons } from "@flow/forms";

const FormGroup = styled.div`
  padding: 10px 0px 0px 0px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; /*Primarybutton is placed to the right but still triggered on enter key*/
`;

const Error = styled.div`
  color: ${Colors.Ferrari};
  padding-bottom: 20px;
`;

class VerifySignersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false
    };
  }

  handleSave(e, values) {
    e.preventDefault();
    const { onSave } = this.props;
    this.setState({ isSaving: true });
    onSave(values, () => {
      this.setState({ isSaving: false });
    });
  }

  render() {
    const { t, onSubmit, taskdata, approvalOptions } = this.props;
    const { isSaving } = this.state;

    return (
      <div>
        <Headings.H6>{t("Selected signers option")}</Headings.H6>
        <Formik
          initialValues={{ approval: taskdata.approval }}
          validate={(values) => {
            const errors = {};
            if (!values.approval) {
              errors.approval = t("Please provide an option");
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, () => {
              setSubmitting(false);
            });
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <RadioButtons options={approvalOptions} name="approval" onChange={handleChange} checkedValue={values.approval} />
              </FormGroup>
              <FormGroup>
                <ErrorMessage name="approval" component={Error} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup>
                  <PrimaryProgressButton type="submit" isLoading={isSubmitting || isSaving}>
                    {t("Complete")}
                  </PrimaryProgressButton>
                  <SecondaryButton
                    disabled={isSaving || isSubmitting}
                    onClick={e => this.handleSave(e, values)}
                  >
                    {t("Save and close")}
                  </SecondaryButton>
                </ButtonGroup>
              </FormGroup>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

VerifySignersForm.propTypes = {
  approvalOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  taskdata: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onSave: PropTypes.func,
  t: PropTypes.func.isRequired
};

VerifySignersForm.defaultProps = {
  taskdata: {},
  onSubmit: () => {},
  onSave: () => {}
};

export default withTranslation("inbox")(VerifySignersForm);
