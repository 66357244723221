/**
 * Maps the id received from input and return the corresponding value in form of the name of the vendor or system.
 * @param {string} input Either vendorId or systemId
 * @returns {string} The value from the table, for example "Conta AS", or the input itself if the mapping is not found. Returns "-" when there is no input.
 */

   const vendorAndSystemMapping = (input) => {
    const mapTable = {
    "998807867": "Conta AS",
    "986559469": "Zirius AS",
    "981012224": "Logiq AS ",
    "986144706": "Brabank",
    "988987344": "Debet AS",
    "911611163": "Kravia AS",
    "996131025": "Husleie.no AS",
    "995250640": "Convene AS",
    "983950418": "Selby AS",
    "915006620": "24SevenFinans AS",
    "980178986": "Mitt Anbud - Schibsted Norge SMB",
    "987290986": "Xledger",
    "936796702": "Visma",
    "925141623": "UniMicro",
    "980386465": "PowerOfficeGo",
    "915790119": "24SevenOffice AI AS"
    }

    if(!input){
      return "-";
    }

    if(mapTable[input] === undefined){
      return input
    }

    return mapTable[input];
  }

export default vendorAndSystemMapping;
