import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Headings, FontStyles } from "@flow/style";

const ContextGroupContainer = styled.div`
  padding: 0 0 10px 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentTitle = styled.p`
  ${FontStyles.Small};
  min-width: 40%;
  padding-right: 10px;

  :first-letter {
    text-transform: uppercase;
  }
`;

const ContentValue = styled.div`
  flex-grow: 2;
`;

const ContextGroup = ({ title, context, t }) => (
  <ContextGroupContainer>
    <Headings.H2>{title}</Headings.H2>
    {
      Object.keys(context).map(key => (
        <Content key={key}>
          <ContentTitle>{t(key)}: </ContentTitle>
          <ContentValue>{t(context[key])}</ContentValue>
        </Content>
      ))
    }
  </ContextGroupContainer>
);

ContextGroup.propTypes = {
  title: PropTypes.string.isRequired,
  context: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation("inbox")(ContextGroup);
