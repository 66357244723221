import { Icons } from "@flow/icons";
import Branding from "./plugins/Branding";
import CustomerIconLoginPage from "./plugins/CustomerIconLoginPage";
import MainHeaderLogo from "./plugins/MainHeaderLogo";
import ReviewApplication from "./components/ReviewApplication";
import VerifySignersScreen from "./components/VerifySignersScreen";
import AccountNumberCheckScreen from "./components/AccountNumberCheckScreen";
import SendEmailScreen from "./components/SendEmailScreen";
import ManualFollowUpCollateral from "./components/ManualFollowUpCollateral";
import CaseSummary from "./components/CaseSummary";
import FileTab from "./components/FileTab";

const setDimensions = (width, height, maxWidth) => {
  return {
    customWidth: `min(${width}%, ${maxWidth || width + "%"})`,
    customHeight: `${height || width}%`,
  }
}

const CustomComponents = {
  case: {
    caseSummary: {
      component: CaseSummary
    },
    tasks: {
      taskLists: [
        {
          id: "custom-task-list-without-message-tasks",
          title: "Oppgaver",
          filter: (task, index, self) => {
            if (task.taskCategory === "user-task" && task.status === "pending") {
              return task;
            }
            return null;
          }
        },
        {
          id: "custom-failed-task-list",
          title: "Feilede oppgaver",
          filter: (task, index, self) => {
            if (task.status === "failed") {
              return task;
            }
            return null;
          }
        }
      ],
      useNewTasksModule: true,
      taskComponents: {
        "manual-verify-signers": {
          component: VerifySignersScreen,
          ...setDimensions(90)
        },
        "manual-check-account-number-in-kar": {
          component: AccountNumberCheckScreen,
          ...setDimensions(90)
        },
        "manual-review-application": {
          component: ReviewApplication,
          ...setDimensions(90)
        },
        "review-application": {
          component: ReviewApplication //TODO: remove when the last processes of last version is complete
        },
        "verify-signers": {
          component: VerifySignersScreen //TODO: remove when the last processes of last version is complete
        },
        "provide-financial-data": {
          component: SendEmailScreen,
          ...setDimensions(90)
        },
        "manual-follow-up-collateral": {
          component: ManualFollowUpCollateral,
          ...setDimensions(90)
        },
        "provide-missing-data": {
          disabled: true          
        },
        "register-signers": {
          disabled: true
        },
        "sign-document": {
          disabled: true
        },
        "upload-invoice" : {
          disabled: true
        },
        "accept-purchase-of-invoice": {
          disabled: true
        }
      }
    },
    tabs: [
      {
        id: "files-tab",
        title: "files",
        component: FileTab,
        badge: flow => flow.data.files.length,
        hide: flow => !flow.data.files
      }
    ],
    businessStatus: {
      map: flow => {
        let displaystring;
        if (flow && flow.data && !flow.data.businessStatus) {
          displaystring = flow.status;
        }
        if (flow && flow.data && flow.data.businessStatus) {
          displaystring = flow.data.businessStatus;
        }
        return displaystring;
      }
    }
  },
  menu: {
    menuStructure: [
      {
        name: "manual-tasks",
        icon: Icons.ManualWork,
        elems: [
          "manual-review-application",
          "manual-verify-signers",
          "manual-check-account-number-in-kar",
          "manual-send-saft-email",
          "manual-follow-up-collateral"
        ],
        isOpen: false
      },
      {
        name: "onboarding",
        elems: ["all-active-onboardings", "active-non-recourse-factoring", "active-recourse-factoring"],
        icon: Icons.Upload,
        isOpen: false
      },
      { name: "onboarding-deposit", icon: Icons.Upload, isOpen: false },
      { name: "purchase", icon: Icons.Withdrawal, isOpen: false },
      { name: "invoice-credit", icon: Icons.Withdrawal, isOpen: false },
      { name: "create-account", icon: Icons.UserClipboard, isOpen: false },
      { name: "failed-cases", icon: Icons.Alert, isOpen: false },
      { name: "fetch-old-signed-documents", icon: Icons.CaseComplete, isOpen: false }
    ]
  },
  queueDefinition: {
    "create-account": {
      name: "create-account",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=create-account&view=get-company-orgno&sort=createdAt&dir=-1",
      pollingEnabled: false,
      limit: 10
    },
    "all-active-onboardings": {
      name: "all-active-onboardings",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno&sort=createdAt&dir=-1",
      pollingEnabled: true,
      pollingInterval: 10000,
      limit: 10
    },
    "active-non-recourse-factoring": {
      name: "active-non-recourse-factoring",
      queueFilter:
        "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno&data.applicationType=non-recourse-factoring&sort=createdAt&dir=-1",
      pollingEnabled: true,
      pollingInterval: 10000,
      limit: 10
    },
    "active-recourse-factoring": {
      name: "active-recourse-factoring",
      queueFilter:
        "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno&data.applicationType=recourse-factoring&sort=createdAt&dir=-1",
      pollingEnabled: true,
      pollingInterval: 10000,
      limit: 10
    },
    "onboarding-deposit": {
      name: "onboarding-deposit",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=onboarding-deposit&view=get-company-orgno&sort=createdAt&dir=-1",
      pollingEnabled: false,
      limit: 10
    },
    purchase: {
      name: "purchase",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=purchase&view=get-company-orgno&sort=createdAt&dir=-1",
      pollingEnabled: true,
      pollingInterval: 10000,
      limit: 10
    },
    "invoice-credit": {
      name: "invoice-credit",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=invoice-credit&view=get-company-orgno&sort=createdAt&dir=-1",
      pollingEnabled: true,
      pollingInterval: 10000,
      limit: 10
    },
    "manual-review-application": {
      name: "manual-review-application",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno",
      taskFilter: "/?taskType=manual-review-application&status=pending&sort=createdAt&dir=-1",
      queueType: "task",
      pollingEnabled: false
    },
    "manual-verify-signers": {
      name: "manual-verify-signers",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno",
      taskFilter: "/?taskType=manual-verify-signers&status=pending&sort=createdAt&dir=-1",
      queueType: "task",
      pollingEnabled: false
    },
    "manual-check-account-number-in-kar": {
      name: "manual-check-account-number-in-kar",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno",
      taskFilter: "/?taskType=manual-check-account-number-in-kar&status=pending&sort=createdAt&dir=-1",
      queueType: "task",
      pollingEnabled: false
    },
    "manual-send-saft-email": {
      name: "manual-send-saft-email",
      queueFilter: "?status=!completed&status=!archived&flowDefinitionId=onboarding&view=get-company-orgno",
      taskFilter: "/?taskType=provide-financial-data&status=pending&sort=createdAt&dir=-1",
      queueType: "task",
      pollingEnabled: false
    },
    "manual-follow-up-collateral": {
      name: "manual-follow-up-collateral",
      queueFilter: "?status=active",
      taskFilter: "/?taskType=manual-follow-up-collateral&status=pending&sort=createdAt&dir=-1",
      queueType: "task",
      pollingEnabled: false
    },
    "failed-cases": {
      /*
       * FIXME:
       * Not possible to use a view for "failed-cases" and "fetch-old-signed-documents". The required "flowDefinitionId" parameter that locates the view file, is also used as an actual MongoDB querry filter.
       * There is a need for a seperate query parameter that specifies the view path without having any implication on the actual fetching of flows from MongoDB. This would also reduce unnecessary redundant views across seperate processes.
       */
      name: "failed-cases",
      queueFilter: "?status=!completed&status=!archived&tasks.status=failed&sort=createdAt&dir=-1",
      pollingEnabled: false
    },
    "fetch-old-signed-documents": {
      name: "fetch-old-signed-documents",
      queueFilter: "?status=!archived&flowDefinitionId=fetch-old-signed-documents&sort=createdAt&dir=-1",
      pollingEnabled: false,
      limit: 10
    }
  }
};

const SearchFields = ["data.company.name", "data.company.orgNo", "data.applicant.name"];

export { Branding, CustomerIconLoginPage, MainHeaderLogo, CustomComponents, SearchFields };
