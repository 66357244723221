import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation as translate } from "react-i18next";
import { Colors } from "@flow/style";
import { PrimaryProgressButton, SecondaryButton } from "@flow/buttons";
import { addYears } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { findRejectionReason, findDocumentRegistrationNo, findRegistrationDate, findDocumentRegistrationStatus } from "../util/brregTinglysingHelpers";
class ManualFollowUpCollateral extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();

    const { task = {} } = this.props;
    const { reservedAccountNo, collateral, company } = this.props.flow.data;
    const { registrationStatus } = collateral;
    const { data } = task;

    // Try to populate varible values with whatever we was saved in the input forms. If form data not present we provide default values for them.
    const proceedByUpdatingManuallyInCoreToUse = data.proceedByUpdatingManuallyInCore ? data.proceedByUpdatingManuallyInCore : true;
    const registrationDocumentNoToUse = data.registrationDocumentNo ? data.registrationDocumentNo : findDocumentRegistrationNo(registrationStatus);
    const rejectionReasonToUse = data.rejectionReason ? data.rejectionReason : findRejectionReason(registrationStatus);
    const registrationDateToUse = data.registrationDate ? data.registrationDate : findRegistrationDate(registrationStatus);
    const expirationDateToUse = data.expirationDate ? data.expirationDateToUse : this.add20yearsToDate(registrationDateToUse);

    this.state = {
      ...this.props.formData,
      company: company,
      proceedByUpdatingManuallyInCore: proceedByUpdatingManuallyInCoreToUse,
      registrationStatus: registrationStatus,
      reservedAccountNo: reservedAccountNo,
      registrationDocumentNo: registrationDocumentNoToUse,
      rejectionReason: rejectionReasonToUse,
      expirationDate: expirationDateToUse,
      registrationDate: registrationDateToUse,
      isSubmitting: false,
      containsInvalidInput: false,
      isSaving: false,
      validationErrors: [],
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }


  componentDidMount = () => {
    // Validate input upon mounting, in case the task gets instantiated with malformed input.
    this.isInputValid(this.state.registrationDocumentNo, "Dagboknummer");
    this.isInputValid(this.state.registrationDate, "Dagbokdato");
  }


  /**
   * Takes in an input field, as well as it's value. And determines wether or not it is considered valid input.
   * Different validation rules will be selected depending on the input names given.
   *
   * @param input Value in the input field
   * @param inputName Identifier for the input field
   * @returns True if the form is validated, false otherwise.
   */

  isInputValid(input, inputName, dontValidate = false) {
    let errors = this.state.validationErrors;

    // Skip validation alltogether, should the caseworker decide not to re-send the collateral to Brreg.
    if (dontValidate) {
      this.setState({ validationErrors: errors, containsInvalidInput: false });
      return true
    }

    // RegEx Patterns used for validation.
    const validDagbokNr = /^[0-9]+$/; // One or more numbers only =>  "123"
    const validDateInput = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/ // ISO8601DateFormat => "yyyy-mm-dd"

    // Error message objects.
    const dagbokNrError = { fieldName: "Dagboknummer", errorMessage: "Dagboknummer må fylles ut med et gyldig tall" };
    const invalidDateError = { fieldName: "Dagbokdato", errorMessage: "Dagbokdato må ha følgende format: YYYY-MM-DD" };

    if (inputName === "Dagboknummer") {
      if (validDagbokNr.test(input)) {
        // If validation OK, we filter away all the error messages related to "Dagboknummer" input from the errors list.
        errors = errors.filter(error => error.errorMessage !== dagbokNrError.errorMessage);
      }

      else {
        // If validation is not OK, we add a corresponding error object to the error list.
        const index = errors.findIndex(obj => obj.errorMessage === dagbokNrError.errorMessage);
        if (index === -1) {
          // Check for duplicate before pushing the error object.
          errors.push(dagbokNrError)
        }
      }
    }

    if (inputName === "Dagbokdato") {
      if (validDateInput.test(input)) {
        errors = errors.filter(error => error.errorMessage !== invalidDateError.errorMessage);
        // If the registration date is valid, we add 20 years to it and fill out the expiration.
        this.setState({ expirationDate: this.add20yearsToDate(input) });
      }

      else {
        const index = errors.findIndex(obj => obj.errorMessage === invalidDateError.errorMessage)
        if (index === -1) {
          errors.push(invalidDateError)
          this.setState({ expirationDate: "Ugyldig dagbokdato." });
        }
      }
    }

    if (errors.length > 0) {
      this.setState({ validationErrors: errors, containsInvalidInput: true });
      return false;
    } else {
      this.setState({ validationErrors: errors, containsInvalidInput: false });
    }
    return true;
  }

  /**
 * The action taken when the complete (fullfør) button is clicked. Before completing, it will validate
 * the input in the form and if this is not valid, it will stop the complete event.
 */
  handleComplete() {
    const { task, onComplete, close, updateCase } = this.props;
    const { rejectionReason, registrationDocumentNo, registrationDate, expirationDate, comment, proceedByUpdatingManuallyInCore } = this.state;

    this.setState({ isSubmitting: true });

    const formData = {
      rejectionReason: rejectionReason,
      comment: comment,
      proceedByUpdatingManuallyInCore: proceedByUpdatingManuallyInCore,
      registrationDocumentNo: registrationDocumentNo, //Dagboknr
      registrationDate: registrationDate, //Dagbokdato
      expirationDate: expirationDate,
    };

    onComplete(task.taskId, formData,
      () => {
        updateCase();
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
        console.error("Could not complete task");
        this.setState({ isSubmitting: false });
      }
    );
  }

  /**
   * The action taken when the save and close (Lagre og lukk) button is clicked.
   */
  handleSave() {
    const { task, onSave, close, updateCase } = this.props;
    const { rejectionReason, registrationDocumentNo, registrationDate, expirationDate, comment, proceedByUpdatingManuallyInCore } = this.state;

    this.setState({ isSubmitting: true });

    const formData = {
      rejectionReason: rejectionReason,
      comment: comment,
      proceedByUpdatingManuallyInCore: proceedByUpdatingManuallyInCore,
      registrationDocumentNo: registrationDocumentNo,
      registrationDate: registrationDate,
      expirationDate: expirationDate,
    };

    onSave(task.taskId, formData,
      () => {
        this.setState({ loading: true });
        updateCase();
        this.setState({ loading: false });
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
        console.error("Could not save task");
        this.setState({ isSubmitting: false });
      }
    );
  }

  formatDate = (dateString) => {
    try {
      const dateObject = new Date(dateString)
      return dateObject.toISOString().split("T")[0]
    } catch {
      console.warn(`Invalid date string: ${dateString}`)
    }
    return "Ugyldig dato"
  }

  add20yearsToDate = (dateString) => {
    try {
      const dateObject = new Date(dateString);
      const dateObjectPluss20Years = addYears(dateObject, 20);
      return dateObjectPluss20Years.toISOString().split("T")[0]
    } catch (error) {
      console.warn(`Invalid date string: ${dateString}`)
    }
    return "Ugyldig dagbokdato";
  }

  render() {
    const { isSubmitting } = this.state;
    const { containsInvalidInput } = this.state;
    const { proceedByUpdatingManuallyInCore } = this.state;
    const { registrationStatus } = this.state;
    const { reservedAccountNo } = this.state;
    const { validationErrors } = this.state;

    return (
      <ScreenContainer>
        <LeftContent>
          <InformationText>
            Elektronisk tinglysing er avvist av Brønnøysund. Sjekk returmelding til høyre, og velg videre handling.  </InformationText>

          <FormGroup>
            <input
              type="radio"
              name="update-collateral"
              value={true}
              checked={proceedByUpdatingManuallyInCore}
              onChange={(event) => {
                this.setState({ proceedByUpdatingManuallyInCore: !proceedByUpdatingManuallyInCore })
                this.isInputValid("", event.target.name, false);
              }} />
            <label for="update-collateral">Oppdater sikkerhet manuelt før prossesen går videre</label>
            <br />
            <input
              type="radio"
              name="continue-without-updating"
              value={false}
              checked={!proceedByUpdatingManuallyInCore}
              onChange={(event) => {
                this.setState({ proceedByUpdatingManuallyInCore: !proceedByUpdatingManuallyInCore })
                this.isInputValid("", event.target.name, true);
              }} />
            <label for="continue-without-updating">Fortsett uten å oppdatere sikkerhet</label>
            <br />
          </FormGroup>

          {!proceedByUpdatingManuallyInCore &&
            <>

              <WarningText>⚠️ Husk å fjerne sikkerhet manuelt for konto {reservedAccountNo} i Core hvis prosessen skal kjøres videre </WarningText>

              <Label style={{ paddingBottom: "15px" }} for="review-comment">Valgfri Kommentar</Label>
              <textarea
                style={{ resize: "vertical", width: "85%", fontFamily: "IBM Plex Sans", padding: "10px", borderRadius: "5px" }}
                id="review-comment"
                defaultValue={this.state.comment}
                rows="6"
                onKeyUp={event => this.setState({ comment: event.target.value })}
              />

            </>}

          {proceedByUpdatingManuallyInCore &&
            <Form>
              <br></br>
              <Label for="registrationDocumentNo">Dagboknummer
                {validationErrors.filter(el => el.fieldName === "Dagboknummer").length > 0 ?
                  <ToolTip href="#">
                    <ToolTipText>{validationErrors.filter(el => el.fieldName === "Dagboknummer")[0].errorMessage}</ToolTipText>
                  </ToolTip> : null}
              </Label>
              <Input name="Dagboknummer" id="registrationDocumentNo" type="number" defaultValue={this.state.registrationDocumentNo}
                onKeyUp={event => {
                  this.setState({ registrationDocumentNo: event.target.value })
                  this.isInputValid(event.target.value, "Dagboknummer");
                }} />


              <Label for="registrationDate">Dagbokdato
                {validationErrors.filter(el => el.fieldName === "Dagbokdato").length > 0 ?
                  <ToolTip href="#">
                    <ToolTipText>{validationErrors.filter(el => el.fieldName === "Dagbokdato")[0].errorMessage}</ToolTipText>
                  </ToolTip> : null}
              </Label>
              <Input name="Dagbokdato" id="registrationDate" defaultValue={this.state.registrationDate}
                onKeyUp={
                  event => {
                    this.setState({ registrationDate: event.target.value })
                    this.isInputValid(event.target.value, "Dagbokdato");
                  }
                } />

              <Label for="expirationDateToUse">Utløpsdato</Label>
              <Input disabled id="expirationDateToUse" value={this.state.expirationDate}
              />

              <Label for="accountNumber">Dagbokstatus</Label>
              <Input disabled id="dagbokStatus" defaultValue={"TINGLYST"} />
            </Form>
          }



        </LeftContent>

        <RightContent>
          <Group style={{ color: "#767676", marginLeft: "5%", marginTop: "5%" }}>
            <FlexRow>
              <b>Returmelding</b>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 55 }}>Dagboknummer:</p>
              <p>{findDocumentRegistrationNo(registrationStatus)}</p>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 69 }}>Dagbokstatus:</p>
              <p>{findDocumentRegistrationStatus(registrationStatus)}</p>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 125 }}>Dato:</p>
              <p>{this.formatDate(findRegistrationDate(registrationStatus))}</p>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 120 }}>Årsak:</p>
              <p>{this.state.rejectionReason}</p>
            </FlexRow>
          </Group>


          <Group style={{ color: "#767676", marginTop: "10%", marginLeft: "5%" }}>
            <FlexRow>
              <b>Selskap</b>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 105 }}>Selskap:</p>
              <p>{this.state.company.name}</p>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 115 }}>Org.nr:</p>
              <p>{this.state.company.orgNo}</p>
            </FlexRow>
            <FlexRow>
              <p style={{ marginRight: 102 }}>Adresse:</p>
              <p>{this.state.company.businessAddress}</p>
            </FlexRow>
          </Group>

          {validationErrors && (validationErrors.length > 0 && proceedByUpdatingManuallyInCore) &&  (
              <Errors>
                <b>Fiks følgende feil for å kunne lagre eller fullføre:</b>
                {validationErrors.map(x => {
                  return <p key={uuidv4()}>• {x.errorMessage}</p>;
                })
                }
              </Errors>
            )
          }

          <ButtonArea>
            <SecondaryButton
              style={{ margin: "0 11%" }}
              disabled={containsInvalidInput}
              onClick={() => this.handleSave()}
              color="white">

              Lagre og lukk
            </SecondaryButton>
            <PrimaryProgressButton
              type="submit"
              onClick={() => this.handleComplete()}
              disabled={containsInvalidInput}
              isLoading={isSubmitting}
            >
              Fullfør
            </PrimaryProgressButton>
          </ButtonArea>
        </RightContent>
      </ScreenContainer >
    );
  }
}

ManualFollowUpCollateral.propTypes = {
  task: PropTypes.shape({}).isRequired,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

ManualFollowUpCollateral.defaultProps = {
  close: () => { },
  updateCase: () => { }
};


const ToolTipText = styled.span`
  visibility: hidden;
  width: 12vw;
  background-color: ${Colors.Ferrari};
  font-size: 90%;
  color: white;
  text-align: center;
  padding: 25px 20px;
  border-radius: 6px;
  position: fixed;
  font-weight: normal;
  z-index: 10;

  &:hover {
    visibility: visible;
  }
`

const Group = styled.div`
  :not(:last-of-type) {
    border-bottom: none;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  p {
    display: "inline";
    float: "left";
  }
`;

const Errors = styled.div`
  position: absolute;
  bottom: 15%;
  margin-left: 20px;
  padding-top: 30px;
  p {
      color: ${Colors.Ferrari};
  }
`;

//Info Popups on Hover
const ToolTip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 0.5%;

  &:before{
    content: "!";
    color: white;
    display: inline-block;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 1.8ex;
    height: 1.8ex;
    font-size: 1.4ex;
    line-height: 1.8ex;
    border-radius: 1.2ex;
    margin-right: 4px;
    padding: 1px;

    background: ${Colors.Ferrari};
    border: 1px solid ${Colors.Ferrari};
    text-decoration: none;
  }

  &:hover:before {
    color: white;
    background: black;
    border-color: white;
    text-decoration: none;
  }

  &:hover {
    ${ToolTipText} {
      visibility: visible;
    }
  }
`

const WarningText = styled.p`
  color: ${Colors.Ferrari};
  margin: 25px;
`

const ContextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 400px;
`;

const ButtonArea = styled(ContextArea)`
  width: 100%;
  flex-direction: row;
  margin-top: auto;
  padding: 0px 0px 20px 0px;
  border-top: ${borderStyle};
`;

const FormGroup = styled.div`
  padding: 10px 0px 0px 0px;
`;

const InformationText = styled.p`
  max-width: 675px;
  padding: 20px 20px 20px 0px;
`


const ScreenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const LeftContent = styled.div`
  overflow: auto;
  padding: 20px 50px;
  width: 100%;
`;

const borderStyle = `1px solid ${Colors.Grey3}`;
const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 35%;
  border-left: ${borderStyle};

`;

const Form = styled.form`
  display: flow-root;
  justify-items: center;
`;

const Label = styled.label`
  display: flex;
  color: black;
`;

const Input = styled.input`
  transition: all 0.2s ease-out;
  height: 16px;
  width: 50%;
  border-radius: 4px;
  border: 2px solid #eee;
  outline-color: #0200a0;
  margin: 5px 4px 10px 0;
  font-size: 14px;
  color: #555;
  padding: 5px;
  resize: none;
  overflow: auto;

  &:focus {
    outline: none !important;
    border: 2px solid #0200a0;
  }
`;


export default translate("inbox")(ManualFollowUpCollateral);