import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "@flow/style"

export default class ContextEntry extends Component {
  render() {
    let key = 0;
    const { title = "", main = "", subTitles = [] } = this.props;
    return (
      <EntryFrame>
        <p style={{ color: Colors.Grey1, fontSize: 15, marginBottom: 4 }}>{title}</p>
        <p style={{ color: Colors.Coal, fontSize: 14, fontWeight: 500, marginBottom: 2 }}>{main}</p>
        {
          subTitles.map(elem =>
            <p key={key++} style={{ color: Colors.Coal, fontSize: 13 }}>
              {elem}
            </p>
          )
        }
      </EntryFrame >
    );
  }
}

const EntryFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 1.5em;
`;
