import React, { Component } from "react";
import styled from "styled-components";

export default class Checkbox extends Component {
  render() {
    return (
      <CheckboxFrame color={this.props.checked ? "#0600ff" : undefined}>
        <InnerCheckbox color={this.props.checked ? "#0600ff" : undefined}>
          <div style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: "white" }} />
        </InnerCheckbox>
      </CheckboxFrame>
    );
  }
}

const InnerCheckbox = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: ${props => (props.color ? props.color : "white")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
`;

const CheckboxFrame = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 9px;
  border: 2px solid #aaa;
  background-color: white;
  border-color: ${props => (props.color ? props.color : "#DDD")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
`;
