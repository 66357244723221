import styled from "styled-components";
import { Colors } from "@flow/style";

const borderStyle = `1px solid ${Colors.Grey3}`;
const groupPadding = "20px";

export const ScreenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const LeftContent = styled.div`
  overflow: auto;
  padding: 20px 50px;
  width: 100%;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 25%;
  border-left: ${borderStyle};
  padding: 20px 0px;
`;

export const Group = styled.div`
  :not(:last-of-type) {
    border-bottom: ${borderStyle};
  }
`;

export const GroupPadded = styled(Group)`
  padding: ${groupPadding};
`;

export const GroupPaddedGrow = styled(GroupPadded)`
  flex-grow: 2;
  overflow: auto;
`;

export const Box = styled.div`
    padding: 10px;
    border: 1px solid ${Colors.SeaLight};
    border-radius: 5px;
    margin: 20px 0px;
`;