import {parse} from "date-fns";

/**
   * The data structure received from Brreg lacks structure, and programatically retreiving the rejection reason is hard. This function traverses the individual
   * text line objects that is received from Brreg, and assumes that the object that is adjacent to the one containing the message "Dokumentet er nektet tinglyst.
   * Nektingsårsak(er):" contains the actuall error message. It will return this string if it is able to find it.
  */

  /**
   * @param regiStrationStatus Array of status messages received from Brreg when the document registration was attempted.
   * @returns {String} - Returns the reason for rejecting the document registration.
   */
   export const findRejectionReason = (registrationStatus) => {
    try {
      const statusMedDagbok = registrationStatus.find(el => el.dagbok !== null);
      const dagbokUtskrift = statusMedDagbok.henvendelse.data.elements[0].elements[0].elements.find(el => el.name === "ns:dagbokutskrift").elements;

      for (let index = 0; index < dagbokUtskrift.length; index++) {
      const tekstlinjeObjekt = dagbokUtskrift[index];
      if (tekstlinjeObjekt.elements) {
        let tekst = tekstlinjeObjekt.elements[0].text;
        if (tekst.trim() === "Dokumentet er nektet tinglyst. Nektingsårsak(er):") {
          const tekstlinjeObjekt = dagbokUtskrift[index + 1];
          const feilMelding = tekstlinjeObjekt.elements[0].text.trim();
          return feilMelding;
        }
      }
    }
    } catch (error) {
      console.warn(`Could not find nektingsårsak: ${error}`)
    }

    return "Ikke oppgitt"
  };

  export const findRegistrationDate = (registrationStatus) => {
      try {
        const brregStatus = registrationStatus.find(el => el.dagbok !== null);
        const melding = brregStatus.henvendelse.data.elements.find(el => el.name = "ns:melding");
        const skjemainnhold = melding.elements.find(el => el.name === "ns:skjemainnhold");
        const dagbok = skjemainnhold.elements.find(el => el.name === "ns:dagbok");
        const dagbokdato = dagbok.elements.find(el => el.name === "ns:dagbokdato").elements.find(el => el.type === "text").text;
        const formattedDagbokDato = parse(dagbokdato, "yyyy-mm-dd", new Date()).toLocaleDateString("fr")
        return formattedDagbokDato;

      } catch (error) {
        console.warn(`Could not find dagbokdato: ${error}`);
    }
    return Object.values(registrationStatus).pop() || "N/A"
  };

  export const findDocumentRegistrationNo = (registrationStatus) => {
    try {
    const brregStatus = registrationStatus.find(el => el.dagbok !== null);
    const melding = brregStatus.henvendelse.data.elements.find(el => el.name = "ns:melding");
    const skjemainnhold = melding.elements.find(el => el.name === "ns:skjemainnhold");
    const dagbok = skjemainnhold.elements.find(el => el.name === "ns:dagbok");
    const dagboknummer = dagbok.elements.find(el => el.name === "ns:dagboknummer").elements.find(el => el.type === "text").text;
    return dagboknummer;

    } catch (error) {
        console.warn(`Could not find dagboknummer: ${error}`);
    }
    return Object.values(registrationStatus).pop() || "N/A"
};

export const findDocumentRegistrationStatus = (registrationStatus) => {
  try {
    const statusMedDagbok = registrationStatus.find(el => el.dagbok !== null);
    const dagbok = statusMedDagbok.dagbok;
    const dagbokStatus = dagbok.status;
    return dagbokStatus
  } catch (error) {
      console.warn(`Could not find dagbokstatus: ${error}`);
  }
  return Object.values(registrationStatus).pop() || "N/A"
};

