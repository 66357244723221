import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { FontStyles, Headings } from "@flow/style";

const SignatureTexts = ({texts}) => {
  const textsToShow = texts.map(text => {
    return (
      <li key={uuidv4()} style={{ }}>
        <Text>{text}</Text>
      </li>
    );
  });

  return (
    <List>
       <Headings.H2>Signaturtekst registrert på firma</Headings.H2>
      {textsToShow}
    </List>
  );
}


SignatureTexts.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string)
};

SignatureTexts.defaultProps = {
  texts: []
};

export default SignatureTexts;

const Text = styled.p`
  ${FontStyles.Normal};
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding-top: 5px;

  li {
    padding-top: 5px;
  }
`;