import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { Colors, Headings, FontStyles } from "@flow/style";
import { Icons } from "@flow/icons";

import VerifySignersForm from "./VerifySignersForm";
import ContextGroup from "./ContextGroup";
import SignatureRules from "./SignatureRules";
import SignatureTexts from "./SignatureTexts";
import { ScreenContainer, RightContent, LeftContent, GroupPadded, GroupPaddedGrow, Box } from "./TaskScreenStyle";

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding-top: 5px;

  li {
    padding-top: 5px;

    div {
      display: flex;
      align-items: center;

      p {
        padding-left: 5px;
        font-style: ${FontStyles.Small};
      }
    }

    ul {
      padding: 0px 15px;
    }
  }
`;

class VerifySignersScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFormSubmit(formData, callback = () => { }) {
    const { task, onComplete, close, updateCase } = this.props;
    const { context } = task;
    const approveData = {
      ...formData,
      ...formData.approval && { signers: context.registeredSigners }
    };

    onComplete(task.taskId, approveData,
      () => {
        updateCase();
        close();
      },
      () => { callback(); }
    );
  }

  onFormSave(formData, callback = () => { }) {
    const { task, onSave, close, updateCase } = this.props;
    const { context } = task;
    const approveData = {
      ...formData,
      ...formData.approval && {signers: context.registeredSigners }
    };

    onSave(task.taskId, approveData,
      () => {
        updateCase();
        close();
      },
      () => { callback(); }
    );
  }

  render() {
    const { task, t, schema } = this.props;
    const { context } = task;

    const signatureRules = context?.signatureRules[0]?.relationType === "Inconclusive" ?
      "Experian data returned inconclusive signature rules" :
      context.signatureRules.flat(Infinity)
      .filter(rule => rule.requirements.map(rq => rq.persons.length >= 1));

    const company = context.company;
    const companyContext = { ...company };
    delete companyContext.name;

    const approvalSchema = schema.properties.approval;
    const approvalOptions = approvalSchema.enum.map((a, index) => ({
      value: a,
      label: t(approvalSchema.enumNames[index])
    }));

   const registeredSigners = context.registeredSigners || [];
   const signersRegisteredByCustomer = registeredSigners.map(x => {
     const signerRoles = x.positions && x.positions.map(po => `${po.description}, `);
      return (
        <li key={x.id}>
          <div>
            <Icons.UserAvatar color={Colors.SeaLight} /><p>{x.nationalId} - {x.name}</p>
          </div>
          <div style={{ paddingLeft: "1.8em", flexDirection: "column", alignItems: "start" }}>
            <p>E-post: {x.email}</p>
            <p>Rolle: {signerRoles}</p>
          </div>
        </li>
      );
   });

    return (
      <ScreenContainer>
        <LeftContent>
          <Box style={{ backgroundColor: Colors.SeaLightest }}>
            <Headings.H2>{t("Signers selected by customer")}:</Headings.H2>
            <List>
              { signersRegisteredByCustomer }
            </List>
          </Box>
          <Box>
            <SignatureTexts texts={context.originalSignatureTexts}/>
          </Box>
          <Box> { signatureRules.includes("inconclusive") ? t(signatureRules) :
            <SignatureRules rules={signatureRules}/> }
          </Box>
        </LeftContent>
        <RightContent>
          <GroupPaddedGrow>
            <ContextGroup title={company.name} context={companyContext} />
         </GroupPaddedGrow>
          <GroupPadded>
            <VerifySignersForm
              approvalOptions={approvalOptions}
              taskdata={task.data}
              onSubmit={(formData, cb) => this.onFormSubmit(formData, cb)}
              onSave={(formData, cb) => this.onFormSave(formData, cb)}
            />
          </GroupPadded>
        </RightContent>
      </ScreenContainer>
    );
  }
}

VerifySignersScreen.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onComplete: PropTypes.func,
  onSave: PropTypes.func,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

VerifySignersScreen.defaultProps = {
  onComplete: () => { },
  onSave: () => { },
  close: () => { },
  updateCase: () => { }
};
 
export default withTranslation("inbox")(VerifySignersScreen);