/**
 * Uses RegEx to validate an email adress: https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
 * @param {string} input An email address
 * @returns {Boolean} Validation result (Boolean)
 */

const validateEmail = (input) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(input).toLowerCase());
}

export default validateEmail;
