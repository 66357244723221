import React from "react";
import styled from "styled-components";

export default class MainLogo extends React.Component {
  render() {
    return (
      <MainLogoWrapper>
        <div style={{ width: "160px", height: "60px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 428 60"
            height="60px"
            width="160px"
            version="1.1"
          >
          <title>Brabank-Logo_Standard</title>
          <g id="Lekeplass" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Logo-/-Positiv" transform="translate(-106.000000, -132.000000)" fill="#0B1214" fillRule="nonzero">
          <g id="Brabank-Logo_Standard" transform="translate(106.666667, 132.000000)">
            <path d="M176,56 L211.626822,56 C219.859977,56 228.312683,49.4043035 230.47369,41.2548131 C232.164231,35.0396376 230.191411,30.4416761 224.900236,28.2536806 L224.42977,28.0634202 L224.900236,27.8731597 C231.191935,25.0826727 234.102551,21.0554926 234.604382,14.4280861 C234.956199,10.9062595 233.806215,7.3998289 231.44285,4.78822197 C228.657691,1.74405436 224.304313,0 219.483604,0 L190.901227,0 L176.003136,56 L176,56 Z M209.2149,45.3454134 L191.152142,45.3454134 L194.564588,33.0419026 L212.065924,33.0419026 C213.536914,32.9784824 214.945176,33.6443941 215.851607,34.817667 C216.761174,36.0543601 217.043454,37.7984145 216.510259,39.605889 C215.66342,42.7768969 212.376431,45.3454134 209.2149,45.3454134 L209.2149,45.3454134 Z M197.036103,24.1630804 L200.511279,11.0985277 L215.977064,11.0985277 C217.325734,11.0668177 218.608538,11.6693092 219.420876,12.7474519 C220.424536,14.0475651 220.675452,15.98188 220.110892,18.011325 C219.16996,21.4677237 216.165251,24.0996602 213.160541,24.0996602 L197.03924,24.1630804 L197.036103,24.1630804 Z" id="Shape"></path>
            <polygon id="Path" points="358.666667 56 372.060942 56 386.666667 0 373.241328 0"></polygon>
            <path d="M395.015634,56 L409.249234,56 L397.288015,31.5099263 C396.16431,28.9052751 396.039454,27.4123653 398.349293,25.3159387 L426.666667,0 L410.84115,0 L386.350617,23.9500851 C384.003383,26.2061499 383.338242,29.7375967 384.699394,32.7169597 L395.012512,56 L395.015634,56 Z" id="Path"></path>
            <path d="M315.286916,13.279004 L315.504087,13.8811545 C315.59716,14.1663837 325.689398,42.4357668 327.330589,46.6508206 C329.223077,51.499717 331.915995,56 338.880974,56 C343.528429,56 347.645367,51.4046406 348.790168,47.1895869 C349.907046,43.1013016 360.374679,3.61290323 361.333333,0 L349.193485,0 L338.511783,42.7843803 L338.294612,42.1505376 C338.201539,41.8653084 328.447467,13.2473118 326.868325,8.81041313 C325.410178,4.75382003 321.231191,0.348613469 315.532009,0.348613469 C310.301295,0.348613469 306.026132,3.61290323 304.664161,8.62026033 C303.609331,12.581777 292.958654,52.3554046 292,56 L304.105722,56 L315.286916,13.279004 L315.286916,13.279004 Z" id="Path"></path>
            <path d="M279.876819,7.84526832 C279.281218,3.64683729 276.340832,0.529820311 272.61676,0.0845321709 C268.080791,-0.456174856 264.482109,1.57942807 261.071511,6.70024168 L228,56 L241.266221,56 L249.494915,43.2138691 L249.745694,43.0548377 L272.024294,43.0548377 C272.181031,43.0548377 272.306421,43.1820628 272.337768,43.3410943 L273.839309,55.9681937 L286.666667,55.9681937 L279.876819,7.84526832 L279.876819,7.84526832 Z M271.083872,33.5765615 C271.021177,33.6401741 270.927135,33.6719804 270.833093,33.6719804 L256.193855,33.6719804 C256.020728,33.6719804 255.880381,33.5295788 255.880381,33.3539175 C255.880381,33.2903049 255.911729,33.2266923 255.943076,33.1630797 L268.300223,13.9838834 C268.345235,13.9113269 268.417211,13.8602809 268.499832,13.8423171 C268.582454,13.8243532 268.668724,13.8409931 268.739086,13.8884645 C268.801781,13.9520771 268.864476,14.0156897 268.864476,14.1111086 L271.146567,33.2903049 C271.177914,33.4175301 271.146567,33.5129489 271.083872,33.5765615 L271.083872,33.5765615 Z" id="Shape"></path>
            <path d="M94.4084601,56 L107.281722,56 L97.7277849,37.9784703 L98.1939822,37.9467422 L98.411541,37.9467422 C109.329882,37.1852691 116.838766,30.0147309 118.482889,18.7512748 C119.166645,14.3728045 117.923452,9.8674221 115.070325,6.50424929 C111.595601,2.34787535 105.979477,0 99.7448653,0 L73.4668771,0 L58.6666667,56 L71.0457591,56 L82.8685228,11.6441926 C82.8996026,11.5172805 83.0239219,11.3903683 83.179321,11.3903683 L99.4029872,11.3903683 C101.713493,11.4302388 103.881254,12.5390594 105.295721,14.4045326 C106.445675,15.8640227 106.818632,17.7994334 106.321355,19.607932 C105.264641,23.5739377 101.233589,26.9053824 97.5102262,26.9053824 L87.2725333,26.9053824 C84.8234435,26.9053824 82.7131237,28.6186969 82.3712457,30.8713881 C82.2469264,31.7280453 82.3401658,32.6164306 82.6820439,33.4413598 L94.4084601,56 L94.4084601,56 Z" id="Path"></path>
            <path d="M0,56 L35.6406786,56 C43.8697912,56 52.3183468,49.4043035 54.4782929,41.2548131 C56.168004,35.0396376 54.1961519,30.4416761 48.9075755,28.2536806 L48.4373405,28.0634202 L48.9075755,27.8731597 C55.196185,25.0826727 58.1053723,21.0554926 58.6069563,14.4280861 C58.9517953,10.9082673 57.7918823,7.38844847 55.415628,4.78822197 C52.6318368,1.74405436 48.2805955,0 43.4622542,0 L14.9252591,0 L0,56 Z M33.2299404,45.3454134 L15.2042652,45.3454134 L18.6181713,33.0419026 L36.1109136,33.0419026 C37.5811817,32.9784824 38.9887518,33.6443941 39.8947379,34.817667 C40.8352079,36.0543601 41.0546509,37.7984145 40.5530669,39.605889 C39.6752949,42.7768969 36.3899197,45.3454134 33.2299404,45.3454134 Z M21.0571236,24.1630804 L24.5305928,11.0985277 L39.9887849,11.0985277 C41.3367919,11.0668177 42.6189661,11.6693092 43.4309052,12.7474519 C44.4340732,14.0475651 44.6848652,15.98188 44.1205832,18.011325 C43.1801132,21.4677237 40.1768789,24.0996602 37.1736447,24.0996602 L21.0602585,24.1630804 L21.0571236,24.1630804 Z" id="Shape"></path>
            <path d="M161.172117,7.82302149 C160.578389,3.63647695 157.647243,0.496568539 153.934874,0.0842573335 C149.381911,-0.454918858 145.825787,1.57492092 142.394659,6.71295286 L109.333333,56 L122.585988,56 L130.82007,43.2183526 L131.10131,43.0597714 L153.400518,43.0597714 C153.556763,43.0597714 153.713007,43.1866364 153.713007,43.3452176 L155.212954,56 L168,56 L161.172117,7.82302149 Z M152.406804,33.5766137 C152.344306,33.6400462 152.250559,33.6717624 152.156813,33.6717624 L137.529207,33.6717624 C137.404211,33.6717624 137.310465,33.6083299 137.247967,33.5131812 C137.185606,33.4169336 137.185606,33.2922664 137.247967,33.1960187 L149.631902,14.0076896 C149.6944,13.8808246 149.850645,13.8491083 149.97564,13.8808246 C150.100636,13.9125408 150.194382,14.0076896 150.225631,14.1662708 L152.50055,33.3546 C152.469302,33.3863162 152.469302,33.4814649 152.406804,33.5766137 L152.406804,33.5766137 Z" id="Shape"></path>
          </g>
          </g>
          </g>
          </svg>
        </div>
      </MainLogoWrapper>
    );
  }
}

export const MainLogoWrapper = styled.div`
  padding-left: 30px;
  display: grid;
  grid-template-columns: 110px 1fr;
`;
