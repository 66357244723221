import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Colors, Headings, FontStyles } from "@flow/style";
import { PrimaryProgressButton } from "@flow/buttons";
import emailValidator from "../util/emailValidator";

class SendEmailScreen extends Component {
  constructor(props) {
    super(props);
    const { task = {} } = this.props;
    const { data = {} } = task;
    const { receiverEmail } = data;

    this.state = {
      isSubmitting: false,
      validationErrors: [],
      receiverEmail: receiverEmail || "",
      showInputFields: true,
    };

    this.handleSendEmailComplete = this.handleSendEmailComplete.bind(this);
    this.handleSkipSAFTFileComplete = this.handleSkipSAFTFileComplete.bind(this);

    this.onReceiverEmailChange = this.onReceiverEmailChange.bind(this);
  }

  onReceiverEmailChange = (e) => {
    const { value } = e.target;
    this.setState({ receiverEmail: value });
  }

  validateForm() {
    const { receiverEmail } = this.state;
    const { t: translate } = this.props;

    const errors = {};

    if (receiverEmail.length > 0) {
      if (!emailValidator(receiverEmail)) {
        errors.accountNumber = translate("invalid-email")
      }
    }

    const errorsAsArray = Object.keys(errors).map(e => {
      return errors[e];
    });

    if (errorsAsArray.length > 0) {
      this.setState({ validationErrors: errorsAsArray, isSubmitting: false });
      return false;
    }
    return true;
  }

  async handleSendEmailComplete() {
    const { receiverEmail } = this.state;
    const { flow, close } = this.props;
    this.setState({ isSubmitting: true });
    const isFormValid = this.validateForm();
    if (!isFormValid) {
      return;
    }

    const postData = {
      email: receiverEmail
    };

    try {
      await axios.post(`/api/flow/flows/${flow.flowId}/messages/send-onboarding-link-receiver`, postData);
      this.setState({ isSubmitting: false });
      this.setState({ emailSendt: true });
      close();
    } catch (error) {
      console.error("Error: Could not perform post request", error);
      this.setState({
        isSubmitting: false,
        validationErrors: ["Kunne ikke sende epost"]
      });
    }
  }
  
  handleSkipSAFTFileComplete() {
    const { task, onComplete, close, updateCase } = this.props;

    this.setState({ isSubmitting: true });

    const formData = {
      skipSAFTFile: true,
    };

    onComplete(task.taskId, formData,
      () => {
        updateCase();
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
        console.error("Could not save task");
        this.setState({ isSubmitting: false });
      }
    );
  }

  render() {
    const { isSubmitting } = this.state;
    const { receiverEmail } = this.state
    const { t: translate } = this.props;
    const { showInputFields } = this.state;

    // RFC 5322 compliant regex disables the send email button if invalid.
    const validEmailEntered = (receiverEmail.length === 0) || (!emailValidator(receiverEmail))
    let button;

    if (validEmailEntered) {
      button = <ProgressButtonDisabled
        type="submit"
        onClick={() => this.handleSendEmailComplete()}
        disabled
      >
        <p>Fyll ut e-post <span role="img" aria-label="writing icon">📝</span></p>
      </ProgressButtonDisabled>
    } else if ((receiverEmail.length > 0)) {
      button = <ProgressButton
        type="submit"
        onClick={() => this.handleSendEmailComplete()}
        disabled={isSubmitting}
        isLoading={isSubmitting}>
        <p>Send <span role="img" aria-label="E-mail icon"> ✉️</span></p>
      </ProgressButton>
    }

    return (
      <ScreenContainer>
          <input 
            id="sendEmail"
            type="radio"
            name="showInputFields"
            checked={showInputFields}
            onChange={() => this.setState({ showInputFields: true })}/>
            <label for="sendEmail">{translate("request-saft-file-through-mail")}</label>
          <div >  
          <input 
            id="skipSAFTFile"
            type="radio" 
            name="showInputFields" 
            onChange={() => this.setState({ showInputFields: false })}/>
            <label for="skipSAFTFile" >{translate("no-saft-file-required")}</label>
          </div>
        <br />
        
        <ProgressButton style={{display: showInputFields ? "none" : "block" }}
        type="submit"
        onClick={() => this.handleSkipSAFTFileComplete()}
        disabled={isSubmitting}
        isLoading={isSubmitting}>
        <p>{this.props.t("continue")}</p>
        </ProgressButton>

        <div style={{display: showInputFields ? "block" : "none" }}>
          <InformationText>Kunden er nødt til å oppgi ytterligere finansiell informasjon før onboarding i form av et gyldig regnskapsdokument i SAF-T format (Standard Audit File for Tax).
            Skriv inn én gyldig e-post adresse i feltet under for å invitere en regnskapsfører eller kontaktperson til å laste opp SAF-t filen via en automatisk generert lenke. E-posten inneholder en lenke til opplastnings-weben og veiledning for opplastning. </InformationText>
          <br />

          <ScreenContainer>
            <Form>
              <FormGroup>
                <InputLabel htmlFor="email">
                  <EmailInputField
                    key="email"
                    type="email"
                    name="email"
                    autocomplete="off"
                    input={" "}
                    placeholder={"ola-nordmann@mail.com"}
                    onChange={this.onReceiverEmailChange}
                  />
                </InputLabel>
              </FormGroup>
              {button}
            </Form>
          </ScreenContainer>
        </div>
      </ScreenContainer >

    );
  }
}

SendEmailScreen.propTypes = {
  task: PropTypes.shape({}).isRequired,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

SendEmailScreen.defaultProps = {
  close: () => { },
  updateCase: () => { }
};

const InformationText = styled.p`
  max-width: 675px;
`

const EmailInputField = styled.input`
    height: 47px;
    border-radius: 4px;
    font-size: 125%;
    text-align: center;
    border-color: black;
    outline: none;
    font-family: "IBM Plex Sans";
`

const ProgressButton = styled(PrimaryProgressButton)`
  margin-left: 10px;
  height: 47px;
`

const ProgressButtonDisabled = styled(PrimaryProgressButton)`
  margin-left: 10px;
  background-color: #aaa;
  height: 47px;
`

const ScreenContainer = styled.div`
  display: flow-root;
  padding: 10%;
  padding-top: 5%;
`

const FormGroup = styled.div`
  padding: 0px 0px 0px 0px;
`;

const Form = styled.form`
  display: flex;
  justify-items: center;
`;

const inputStyle = css`
  border: 1px solid ${Colors.Sea};
  box-sizing: border-box;
  border-radius: 4px;
  ${FontStyles.Normal};
  padding: 10px;
`;


const InputLabel = styled.label`
  input {
      margin-left: 10px;
      ${inputStyle};
  }
`;


export default withTranslation("inbox")(SendEmailScreen);
