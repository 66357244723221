import React from "react";
import ReactDOM from "react-dom";

import { CaseManager, APPS } from "@flow/case-manager";
import * as customerSetup from "./customer";
import * as serviceWorker from "./serviceWorker";
import translatesInbox from "./translate/inbox";
import "./index.css";
import vendorAndSystemMapping from "../src/customer/util/vendorAndSystemMapping";

const skin = {
  branding: customerSetup.Branding,
  customerIcon: customerSetup.CustomerIconLoginPage,
  mainLogo: customerSetup.MainHeaderLogo
};

const customTranslates = [
  {
    app: APPS.Inbox,
    language: "no",
    translates: translatesInbox
  },
  {
    app: APPS.Search,
    language: "no",
    translates: translatesInbox
  }
];

const FlowPortalWithApps = (
  <CaseManager
    configuration={(user) => {
      return {
        skin,
        apps: [APPS.Inbox, APPS.Search, APPS.Process, APPS.Decisions, APPS.Roles],
        customComponents: customerSetup.CustomComponents,
        inboxConfiguration: {
          searchFields: customerSetup.SearchFields,
          queuesPollingEnabled: false,
          features: [],
          startableProcesses: ["create-account", "fetch-old-signed-documents"]
        },
        searchConfiguration:{
          searchProcesses: ["onboarding", "onboarding-deposit", "purchase", "create-account", "invoice-credit", "fetch-old-signed-documents", "remove-expired-applications"],
          searchFields: [
            { name: "Søkers navn", value: "data.applicant.name", process: ["onboarding", "onboarding-deposit", "fetch-old-signed-documents"] },
            { name: "Søkers e-post", value: "data.applicant.email", process: ["onboarding", "onboarding-deposit"] },
            {
              name: "Selskap navn",
              value: "data.company.name",
              process: ["onboarding", "onboarding-deposit", "purchase", "invoice-credit", "fetch-old-signed-documents"]
            },
            {
              name: "Selskap org.nr",
              value: "data.company.orgNo",
              process: ["onboarding", "onboarding-deposit", "purchase", "invoice-credit", "fetch-old-signed-documents"]
            },
            { name: "Debitor navn", value: "data.invoice.debtorName", process: ["purchase", "invoice-credit"] },
            { name: "Debitor org.nr", value: "data.invoice.debtorOrgNo", process: ["purchase", "invoice-credit"] },
            { name: "Debitor e-post", value: "data.invoice.debtorEmail", process: ["purchase", "invoice-credit"] },
            { name: "Ekstern fakturaid", value: "data.invoice.externalId", process: ["purchase", "invoice-credit"] },
            { name: "Fakturanummer", value: "data.invoice.invoiceId", process: ["purchase", "invoice-credit"] },
            { name: "Leverandør ID", value: "data.vendorId", process: ["purchase", "onboarding", "invoice-credit"] },
            { name: "System ID", value: "data.systemId", process: ["purchase", "onboarding", "invoice-credit"] },
            { name: "Onboarding-saksnummer", value: "data.input.flowNr", process: ["fetch-old-signed-documents"] }
          ],
          searchResultFields: [
            { name: "Status", value: "data.businessStatus" },
            { name: "Søker navn", value: "data.applicant.name", process: ["onboarding", "onboarding-deposit"] },
            { name: "Søker e-post", value: "data.applicant.email", process: ["onboarding", "onboarding-deposit"] },
            { name: "Selskap navn", value: "data.company.name" },
            { name: "Selskap org.nr", value: "data.company.orgNo" },
            { name: "Debitor navn", value: "data.invoice.debtorName", process: ["purchase", "invoice-credit"] },
            { name: "Debitor org.nr", value: "data.invoice.debtorOrgNo", process: ["purchase", "invoice-credit"] },
            { name: "Debitor e-post", value: "data.invoice.debtorEmail", process: ["purchase", "invoice-credit"] },
            { name: "Ekstern fakturaid", value: "data.invoice.externalId", mapping: value => value.toString(), process: ["purchase", "invoice-credit"] },
            { name: "Fakturanummer", value: "data.invoice.invoiceId", mapping: value => value.toString(), process: ["purchase", "invoice-credit"] },
            { name: "Sak opprettet", value: "createdAt" },
            { name: "Fjernede saker", value: "data.flowsRemoved.totalNumberOfDeletedFlows", process: ["remove-expired-applications"] },
            {
              name: "Leverandørnavn",
              value: "data.vendorId",
              mapping: value => vendorAndSystemMapping(value),
              process: ["purchase", "onboarding", "invoice-credit"]
            },
            {
              name: "Systemnavn",
              value: "data.systemId",
              mapping: value => vendorAndSystemMapping(value),
              process: ["purchase", "onboarding", "invoice-credit"]
            },
            {
              name: "Selskapsnavn",
              value: "data.contacts",
              mapping: value => value.find(el => el.contactRoleCode === "SIGNER").companyName,
              process: ["create-account"]
            },
            {
              name: "Org.nr",
              value: "data.contacts",
              mapping: value => value.find(el => el.contactRoleCode === "SIGNER").organizationNo,
              process: ["create-account"]
            },
            {
              name: "Kontaktperson fornavn",
              value: "data.contacts",
              mapping: value =>
                (value.find(el => el.contactRoleCode === "CONTACTPERSON") && value.find(el => el.contactRoleCode === "CONTACTPERSON").firstName) || "-",
              process: ["create-account"]
            },
            {
              name: "Kontaktperson etternavn",
              value: "data.contacts",
              mapping: value =>
                (value.find(el => el.contactRoleCode === "CONTACTPERSON") && value.find(el => el.contactRoleCode === "CONTACTPERSON").surname) || "-",
              process: ["create-account"]
            },
            { name: "Kontonummer opprettet", value: "data.accountCreated.accountNumber", process: ["create-account"] },
            { name: "Onboarding-saksnummer", value: "data.input.flowNr", mapping: value => value.toString(), process: ["fetch-old-signed-documents"] }
          ],
          features: []
        },
        loginConfiguration: {},
        translates: customTranslates,
        language: "no"
      }
    }}
  />
);
ReactDOM.render(FlowPortalWithApps, document.getElementById("root"));

serviceWorker.unregister();
