import React, { Component } from "react";
import styled from "styled-components";
import ContextEntry from "./ContextEntry";
import vendorAndSystemMapping from "../util/vendorAndSystemMapping";

export default class Summary extends Component {
  renderContext(processType, data) {

    const sillyMapper = (decisionRules) => {
      const resArr = []
      for (const iterator of Object.keys(decisionRules)) {
        resArr.push(`${iterator}=${decisionRules[iterator]}`)
      }
      return resArr
    }

    if (processType === "onboarding") {
      const preScoringResult = (data.preScoring && data.preScoring.result) || [];
      let decisionRules = [];
      let decision;
      if (Array.isArray(preScoringResult)) {
        const scoringResult = preScoringResult.find(x => x.productName === "Non-Recourse-Factoring");
        if (scoringResult) {
          decision = scoringResult.decision || "-";
        }

        decisionRules = preScoringResult.filter(x => x.productName === "Decision-Rules");
        if (decisionRules.length >= 1) {
          decisionRules = sillyMapper(decisionRules[0].rules);
        }
      }

      let risk = "-";
      if (data && data.riskEvaluation && data.riskEvaluation.riskResult) {
        risk = data.riskEvaluation.riskResult.toString().substring(0, 4);
      } else if (data && data.riskEvaluation) {
        risk = data.riskEvaluation.toString().substring(0, 4);
      }

      let LocalCommon = () => {
        return (
          <CaseSummary>
            <ContextEntry title={this.props.t("product")} main={this.props.t(data.applicationType)} />
            <ContextEntry
              title={this.props.t("company")}
              main={data.company.name}
              subTitles={[`${this.props.t("orgNo")}: ${data.company.orgNo}`]}
            />
            <ContextEntry
              title={this.props.t("applicant")}
              main={data.applicant.name}
              subTitles={[
                `${this.props.t("email")}: ${data.applicant.email}`,
                `${this.props.t("phoneNumber")}: ${data.applicant.phoneNumber}`
              ]}
            />
            <ContextEntry title={this.props.t("accountNumber")} main={data.accountNumber} />
            <ContextEntry title={this.props.t("core-account-number")} main={data.reservedAccountNo} />
            <ContextEntry title={this.props.t("score-result")} main={data.score} />
            <ContextEntry title={this.props.t("risk-evaluation-customer")} main={risk} />
            <ContextEntry title={this.props.t("vendorName")} main={vendorAndSystemMapping(data.vendorId)} />
            <ContextEntry title={this.props.t("systemName")} main={vendorAndSystemMapping(data.systemId)} />
          </CaseSummary>)
      }

      if (!decisionRules.length > 0) {
        return (
          <>
            <LocalCommon />
          </>

        )
      }
      else {
        return (
          <>
            <LocalCommon />
            <CaseSummary>
              <ContextEntry
                title={this.props.t("Scoring decisionRules")}
                subTitles={decisionRules}
              />
              <ContextEntry title={this.props.t("Scoring decision")} main={decision} subTitles={[]} />
            </CaseSummary>
          </>

        )
      }
    } else if (processType === "purchase" || processType === "invoice-credit") {
      const externalId = data.invoice.externalId || "";
      const invoiceId = data.invoice.invoiceId || "";
      const currencyCode = data.invoice.currencyCode || "";
      const purchaseType = (data && data.factoringAccount && data.factoringAccount.type) || "";
      const preScoringResult = (data.preScoring && data.preScoring.result) || {};

      let invoicePurchaseAmount = "";
      let invoiceLoanAmount = "";
      let fees = "";
      if (purchaseType === "RECOURSE") {
        invoiceLoanAmount = (data && data.invoice && data.invoice.loanAmount) || "";
        fees = (data && data.creditor && data.creditor.calculatedPrice && data.creditor.calculatedPrice.sumLoanFee) || "";
      }
      if (purchaseType === "NON-RECOURSE" || purchaseType === "") {
        invoicePurchaseAmount = preScoringResult.invoicePurchaseAmount || "";
        fees = preScoringResult.invoicePurchasePrice || "";
      }

      const decision = (preScoringResult.decision) || 0;
      const price = (preScoringResult.price) || "";

      let prescoreRejection = [];
      let prescoreTitle = "";
      if (preScoringResult.decisionRules) {
        prescoreRejection = sillyMapper(preScoringResult.decisionRules);
        prescoreTitle = "Scoring-decisionRules";
      } else if (preScoringResult.error) {
        prescoreRejection.push(`${preScoringResult.error_type}: ${preScoringResult.error}`);
        prescoreTitle = "Scoring-error";
      }

      let LocalCommon = () => {
        return (<CaseSummary>
          <ContextEntry title={this.props.t("productType")} main={this.props.t(purchaseType)} />
          <ContextEntry
            title={this.props.t("creditor")}
            main={data.company.name}
            subTitles={[
              `${this.props.t("orgNo")}: ${data.company.orgNo}`,
              `${this.props.t("email")}: ${data.company.email}`
            ]}
          />
          <ContextEntry
            title={this.props.t("debtor")}
            main={data.invoice.debtorName}
            subTitles={[
              data.debitorIsPerson ?
              `${this.props.t("ssn")}: ${data.invoice.debtorSsn}` : `${this.props.t("orgNo")}: ${data.invoice.debtorOrgNo}`,
              `${this.props.t("email")}: ${data.invoice.debtorEmail}`
            ]}
          />
          <ContextEntry title={this.props.t("invoiceId")} main={invoiceId} />
          <ContextEntry title={this.props.t("externalId")} main={externalId} />
          <ContextEntry title={this.props.t("invoiceAmount")} main={`${data.invoice.invoiceAmount} ${currencyCode}`} />
          {purchaseType === "NON-RECOURSE" &&
            <ContextEntry
              title={this.props.t("purchaseAmount")}
              main={invoicePurchaseAmount !== "" ? `${invoicePurchaseAmount},-` : ""}
            />
          }
          {purchaseType === "RECOURSE" &&
            <ContextEntry
              title={this.props.t("mortagingAmount")}
              main={invoiceLoanAmount !== "" ? `${invoiceLoanAmount},-` : ""}
            />
          }
          <ContextEntry title={this.props.t("fees")} main={fees !== "" ? `${fees},-` : ""} />
          <ContextEntry title={this.props.t("dueDate")} main={data.invoice.dueDate} />
          <ContextEntry title={this.props.t("vendorName")} main={vendorAndSystemMapping(data.vendorId)} />
          <ContextEntry title={this.props.t("systemName")} main={vendorAndSystemMapping(data.systemId)} />
        </CaseSummary>)
      }

      if (!prescoreRejection.length > 0) {
        return (
          <>
            <LocalCommon />
          </>
        )
      }
      else {
        return (
          <>
            <LocalCommon />
            <CaseSummary>
              <ContextEntry
                title={this.props.t(prescoreTitle)}
                subTitles={prescoreRejection}
              />
              <ContextEntry title={this.props.t("Scoring decision")} main={decision} />
              <ContextEntry title={this.props.t("Scoring price")} main={`${price} %`} />
            </CaseSummary>
          </>
        )
      }

    } else if (processType === "create-account") {
      const { contacts = [], accountCreated = {} } = data;
      const companyName = contacts.find( el => el.contactRoleCode === "SIGNER").companyName || "-";
      const orgNo = contacts.find( el => el.contactRoleCode === "SIGNER").organizationNo || "-";
      const firstName = (contacts.find(el => el.contactRoleCode === "CONTACTPERSON") && contacts.find(el => el.contactRoleCode === "CONTACTPERSON").firstName) || "-";
      const lastName = (contacts.find(el => el.contactRoleCode === "CONTACTPERSON") && contacts.find(el => el.contactRoleCode === "CONTACTPERSON").surname) || "-";
      const accountNo = accountCreated.accountNumber || "-";

      return (
        <div>
          <CaseSummary>
            <ContextEntry title={this.props.t("Selskapsnavn")} main={companyName} />
            <ContextEntry title={this.props.t("Org.nr")} main={orgNo} />
            <ContextEntry title={this.props.t("Kontaktperson fornavn")} main={firstName} />
            <ContextEntry title={this.props.t("Kontaktperson etternavn")} main={lastName} />
            <ContextEntry title={this.props.t("Kontonummer opprettet")} main={accountNo} />
          </CaseSummary>
        </div>
      )
    } //This shows the CaseSummary for fetch-old-signed-documents
      else if (processType === "fetch-old-signed-documents"){

        const flowId = (data && data.input && data.input.flowNr) || "";
        const orgName = (data && data.company && data.company.name) || "" ;
        const orgNo = (data && data.company && data.company.orgNo) || "" ;
        const applicant = (data && data.applicant && data.applicant.name) || "" ;
        const caseNotFound = (data && data.caseNotFoundError && data.caseNotFoundError.errorMessage) || null;

        if(caseNotFound != null) {
          return (
            <div>
              <CaseSummary>
              <ContextEntry title={this.props.t("Onboarding Saksnummer")} main={flowId} />
              <ContextEntry title={this.props.t("Feilmelding")} main={caseNotFound} />
              </CaseSummary>
            </div>
          )
        }
        return (
          <div>
            <CaseSummary>
              <ContextEntry title={this.props.t("Onboarding Saksnummer")} main={flowId} />
              <ContextEntry title={this.props.t("Selskapsnavn")} main={orgName} />
              <ContextEntry title={this.props.t("Org.nr")} main={orgNo} />
              <ContextEntry title={this.props.t("Søkerens navn")} main={applicant} />
            </CaseSummary>
          </div>
        )
      }
    return <CaseSummary />;
  }
  render() {
    const data = this.props.caseData.data || {};

    return this.renderContext(this.props.caseData.flowDefinitionId, data);
  }
}

const CaseSummary = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(225px, 1fr) );
  min-width: 550px;
  min-height: 105px;
  padding: 10px;
  background-color: #f7fbff;
  border: 1px solid #e6e4e3;
  border-radius: 4px;
`;
