import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { Colors, Headings, FontStyles } from "@flow/style";
import { Icons } from "@flow/icons";

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding-top: 5px;

  li {
    padding-top: 5px;
    
    div {
      display: flex;
      align-items: center;

      p {
        padding-left: 5px;
        font-style: ${FontStyles.Small};
      }
    }

    ul {
      padding: 0px 15px;
    }
  }
`;

const SignatureRules = ({ rules, t }) => {
  const rulesItems = rules.map((rule, index) => {
    return rule.requirements.map((x) => {
      const persons = x.persons.map(p => {
        const signerRoles = p.positions && p.positions.map(po => `${po.description}, `);
        return (
            <li key={p.id}>
                <div><Icons.UserAvatar color={Colors.SeaLight} /><p>{p.nationalId}, {p.name}</p></div>
                <div style={{ paddingLeft: "1.8em" }}><p>Rolle: {signerRoles}</p></div>
            </li>
            );
        });

      return (
          <li key={x.ruleId} style={{ paddingBottom: 15 }}>
              <div>{`Antall personer som må signere for å oppfylle regel: ${x.count}`}</div>
              { x.persons.length > 0 && (
                  <List>
                      { persons }
                  </List>
              )}
          </li>
      )
    });
  });

    return (
        <div>
            <Headings.H2>{t("Signature rules")}:</Headings.H2>
            <List key="ruleList">
                { rulesItems }
            </List>
        </div>
    );
}

SignatureRules.propTypes = {
    t: PropTypes.func.isRequired,
    rules: PropTypes.arrayOf(PropTypes.shape({}))
};

SignatureRules.defaultProps = {
    rules: []
};
 
export default withTranslation("inbox")(SignatureRules);