import React, { Component } from "react";
import brabankLogo from "./Brabank-LogoLiten.png";

export default class CustomerIconLoginPage extends Component {
  render() {
    return (
      <div>
        <img src={brabankLogo} alt="" style={{ width: "55px", height: "55px", borderRadius: "30px" }} />
      </div>
    );
  }
}
