import React, { Component } from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import { Colors } from "@flow/style";
import { PrimaryProgressButton, SecondaryButton } from "@flow/buttons";

export default class ReviewApplication extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();

    const { task= {} } = this.props;
    const { company, business, creditEvaluation, creditEvaluationFailed } = task.context;
    const { data } = task;

    const creditLimitToUse = data.creditLimit ? data.creditLimit : Math.round(creditEvaluation.limit).toString();
    const commissionToUse = data.commission ? data.commission : creditEvaluation.Price.toString();
    const adminFeeToUse = data.adminFee ? data.adminFee : "35";
    const loanPercentToUse = data.loanPercent ? data.loanPercent : "85";
    const establishmentFeeToUse = data.establishmentFee ? data.establishmentFee : "10 000";
    const monthlyFeetToUse = data.monthlyFee ? data.monthlyFee : "200";
    const limitCommissionOfCreditLimitToUse = data.limitCommissionOfCreditLimit ? data.limitCommissionOfCreditLimit : "1";
    const factoringCollateralAmountToUse = data.factoringCollateralAmount ? data.factoringCollateralAmount : Math.round((creditEvaluation.limit * 1.5)).toString();
    const approvedToUse = data.approved ? data.approved : false;
    const commentToUse = data.comment ? data.comment : "";
    const socialSecurityNumberToUse = data.socialSecurityNumber ? data.socialSecurityNumber : "";
    const bailAmountToUse = data.bailAmount ? data.bailAmount : "";
    const bailedByNameToUse = data.bailedByName ? data.bailedByName : "";

    this.state = {
      ...this.props.formData,
      isSubmitting: false,
      validationErrors: [],
      disableSaveAndComplete: false,
      approved: approvedToUse,
      comment: commentToUse,
      company,
      business,
      creditEvaluation,
      loanPercent: loanPercentToUse,
      adminFee: adminFeeToUse,
      establishmentFee: establishmentFeeToUse,
      monthlyFee: monthlyFeetToUse,
      limitCommissionOfCreditLimit: limitCommissionOfCreditLimitToUse,
      creditLimit: parseFloat(creditLimitToUse.replace(/,/g, ".").replace(/\s/g, '')).toLocaleString('fr'),
      commission: commissionToUse,
      factoringCollateralAmount: parseFloat(factoringCollateralAmountToUse.replace(/,/g, ".").replace(/\s/g, '')).toLocaleString('fr'),
      socialSecurityNumber: socialSecurityNumberToUse,
      bailAmount: bailAmountToUse,
      bailedByName: bailedByNameToUse,
      creditEvaluationFailed
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  /**
   * Validates the input in the form, making sure its on the correct form and not empty.
   * @param input Value in the input field
   * @param inputName Identifier for the input field
   * @param canBeEmpty Optional, if true then the input field is valid when empty
   * @returns True if the form is correct, false otherwise.
   */
  isInputValid(input, inputName, canBeEmpty = false) {
    const errors = this.state.validationErrors || [];

    function removeErrors(value, index, array) {
      const insuficcientCollateral = (errors.filter((el) => el.errorMessage === 'Factoringpant kan ikke være mindre en Kjøpslimit').length > 0);
      if((insuficcientCollateral && (inputName === 'Factoringpant' || inputName === 'Kjøpslimit'))){
        array.splice(errors.map((e) => {return e.errorMessage;}).indexOf('Kjøpslimit kan ikke være større enn Factoringpant'),1);
        array.splice(errors.map((e) => { return e.errorMessage; }).indexOf('Factoringpant kan ikke være mindre en Kjøpslimit'),1);
      } else if (value.fieldName === inputName) {
        array.splice(index, 1);
      }
    };

    errors.forEach(removeErrors);

    input = input.replace(/\s/g, '');
    // Populate error message list with error objects:
    if (canBeEmpty && input.length <= 0) {
      // Do nothing
    } else if (!input || input.length <= 0) {
      errors.push({fieldName: inputName, errorMessage: `Vennligst fyll inn feltet ${inputName}`});
    } else if (Number.isNaN(Number(input))) {
      errors.push({fieldName: inputName, errorMessage: `Vennligst skriv inn et gyldig nummer for feltet ${inputName}`});
    } else if (Number(input) < 0) {
      errors.push({fieldName: inputName, errorMessage: `Feltet ${inputName} må ha et positivt tall`});

      // Ensure that the collateral amount isnt lower than the credit limit.
    } else if (inputName === "Kjøpslimit" && parseInt(input) > parseInt(this.state.factoringCollateralAmount.replace(/\s/g, ''))) {
      errors.push({fieldName: inputName, errorMessage: `${inputName} kan ikke være større enn Factoringpant`});
      errors.push({fieldName: 'Factoringpant', errorMessage: `Factoringpant kan ikke være mindre en Kjøpslimit`});

    } else if (inputName === "Factoringpant" && parseInt(input) < parseInt(this.state.creditLimit.replace(/\s/g, ''))) {
      errors.push({fieldName: inputName, errorMessage: `${inputName} kan ikke være mindre en Kjøpslimit`});
      errors.push({fieldName: 'Kjøpslimit', errorMessage: `Kjøpslimit kan ikke være større enn Factoringpant`});
    }

    if (errors.length > 0) {
      this.setState({ validationErrors: errors, canSaveOrComplete: true });
      return false;
    } else {
      this.setState({ validationErrors: errors, canSaveOrComplete: false});
    }
    return true;
  }

  /**
   * The action taken when the save and close (Lagre og lukk) button is clicked.
   */
  handleSave() {
    const { task, onSave, close, updateCase } = this.props;
    const { approved, comment, creditLimit, loanPercent, factoringCollateralAmount, socialSecurityNumber, bailedByName, bailAmount, adminFee,
      establishmentFee, commission, monthlyFee, limitCommissionOfCreditLimit } = this.state;

    this.setState({ isSubmitting: true });

    const formData = {
      approved: approved,
      comment: comment,
      commission: commission,
      adminFee: adminFee,
      monthlyFee: monthlyFee,
      establishmentFee: establishmentFee,
      limitCommissionOfCreditLimit: limitCommissionOfCreditLimit,
      creditLimit: creditLimit,
      loanPercent: loanPercent,
      factoringCollateralAmount: factoringCollateralAmount,
      socialSecurityNumber: socialSecurityNumber,
      bailAmount: bailAmount || "",
      bailedByName: bailedByName
    };

    onSave(task.taskId, formData,
      () => {
        updateCase();
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
        console.error("Could not save task");
        this.setState({ isSubmitting: false });
      }
    );
  }

  /**
   * Adds spacing to make for more readable numbers and validates the input.
   * @param fieldValue id of the field to format
   * @param fieldName name of the field to validate
   */
  handleInputField = (fieldValue, fieldName, canBeEmpty = false) =>  {
    const formatValue = ({value}) => {
      let numb = parseInt(value.replace(/\D/g,''),10);
      if (!numb)
        return value;
      return numb.toLocaleString('fr');
    }
    let valueToValidate = fieldValue.replace(/,/g, ".").replace(/\D/g, '');
    let formattedValue = formatValue({value: valueToValidate});
    this.isInputValid(valueToValidate, fieldName, canBeEmpty);

    return formattedValue;
  }

  /**
   * The action taken when the complete (fullfør) button is clicked. Before completing, it will validate
   * the input in the form and if this is not valid, it will stop the complete event.
   */
   handleComplete() {
    const { task, onComplete, close, updateCase } = this.props;
    const { approved, comment, creditLimit, loanPercent, factoringCollateralAmount, socialSecurityNumber, bailedByName, bailAmount, adminFee,
      establishmentFee, commission, monthlyFee, limitCommissionOfCreditLimit } = this.state;

    this.setState({ isSubmitting: true });

    const formData = {
      approved: approved,
      comment: comment,
      commission: Number(commission),
      adminFee: Number(adminFee),
      monthlyFee: Number(monthlyFee),
      establishmentFee: Number(establishmentFee.replace(/\s/g, '')),
      limitCommissionOfCreditLimit: Number(limitCommissionOfCreditLimit),
      creditLimit: Number(creditLimit.replace(/\s/g, '')),
      loanPercent: Number(loanPercent),
      factoringCollateral: Boolean(Number(factoringCollateralAmount.replace(/\s/g, ''))),
      factoringCollateralAmount: Number(factoringCollateralAmount.replace(/\s/g, '')),
      bail: Boolean(Number(bailAmount.replace(/\s/g, '')) > 0),
      socialSecurityNumber: socialSecurityNumber,
      bailAmount: Number(bailAmount.replace(/\s/g, '' || "")),
      bailedByName: bailedByName
    };

    onComplete(task.taskId, formData,
      () => {
        updateCase();
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
        console.error("Could not complete task");
        this.setState({ isSubmitting: false });
      }
    );
  }

  render() {
    const { isSubmitting, validationErrors, canSaveOrComplete } = this.state;

    return (
      <ScreenContainer style={{ overflow: "hidden" }}>
        <LeftContent>
          <DoubleFlexCol>
            <Group style={{ marginRight:"30px"}}>
              <Title>Kreditt</Title>
              <Label for="creditLimit">
                Kjøpslimit *
              </Label>
              <Input
                id="creditLimit"
                onChange={event => {
                  let creditLimit = this.handleInputField(event.target.value, "Kjøpslimit")
                  this.setState({ creditLimit: creditLimit});
                }}
                value={this.state.creditLimit}
              />
              {validationErrors.filter(el => el.fieldName === "Kjøpslimit").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Kjøpslimit")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }

              <Label for="loanPercent">
                Finansieringsgrad *
              </Label>
              <div>
                <Input
                  id="loanPercent"
                  defaultValue={this.state.loanPercent}
                  onChange={ event => {
                    this.setState({ loanPercent: event.target.value.replace(/,/g, ".") })
                    this.isInputValid(event.target.value.replace(/,/g, "."), "Finansieringsgrad");
                  }}
                />
                <span>%</span>
              {validationErrors.filter(el => el.fieldName === "Finansieringsgrad").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Finansieringsgrad")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }
              </div>
              <Label for="factoringCollateralAmount">
                Factoringpant *
              </Label>
              <Input
                id="factoringCollateralAmount"
                onChange={event => {
                  let factoringCollateralAmount = this.handleInputField(event.target.value, "Factoringpant");
                  this.setState({ factoringCollateralAmount: factoringCollateralAmount});
                }}
                value={this.state.factoringCollateralAmount}
              />
              {validationErrors.filter(el => el.fieldName === "Factoringpant").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Factoringpant")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }

              <Title>Selvskyldnerkausjon</Title>
              <p>Alle tre felt under må være fylt inn dersom det skal være kausjon</p>
              <Label for="socialSecurityNumber">
                Fødselsnummer
              </Label>
              <Input
                id="socialSecurityNumber"
                defaultValue={this.state.socialSecurityNumber}
                onChange={ event => this.setState({ socialSecurityNumber: event.target.value }) }
              />
              <Label for="bailedByName">
                Navn
              </Label>
              <Input
                id="bailedByName"
                defaultValue={this.state.bailedByName}
                onChange={ event => this.setState({ bailedByName: event.target.value }) }
              />
              <Label for="bailAmount">
                Beløp
              </Label>
              <Input
                id="bailAmount"
                onChange={event => {
                  let bailAmount = this.handleInputField(event.target.value, "Beløp", true)
                  this.setState({ bailAmount: bailAmount});
                }}
                value={this.state.bailAmount}
              />
            </Group>
            <Group>
              <Title>
                Pris
              </Title>
              <Label for="adminFee">
                Faktura-administrasjonsgebyr *
              </Label>
              <Input
                id="adminFee"
                defaultValue={this.state.adminFee}
                onChange={ event => {
                  this.setState({ adminFee: event.target.value.replace(/,/g, ".") })
                  this.isInputValid(event.target.value.replace(/,/g, "."), "Faktura-administrasjonsgebyr");
                }}
              />
              {validationErrors.filter(el => el.fieldName === "Faktura-administrasjonsgebyr").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Faktura-administrasjonsgebyr")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }
              <Label for="establishmentFee" >
                Etableringsgebyr *
              </Label>
              <Input
                id="establishmentFee"
                onChange={event => {
                  let establishmentFee = this.handleInputField(event.target.value, "Etableringsgebyr")
                  this.setState({ establishmentFee: establishmentFee});
                }}
                value={this.state.establishmentFee}
              />
              {validationErrors.filter(el => el.fieldName === "Etableringsgebyr").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Etableringsgebyr")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }
              <Label for="monthlyFee">
                Månedsgebyr *
              </Label>
              <Input
                id="monthlyFee"
                defaultValue={this.state.monthlyFee}
                onChange={ event => {
                  this.setState({ monthlyFee: event.target.value.replace(/,/g, ".") })
                  this.isInputValid(event.target.value.replace(/,/g, "."), "Månedsgebyr");
                }}
              />
              {validationErrors.filter(el => el.fieldName === "Månedsgebyr").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Månedsgebyr")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }
              <Label for="commission">
                Pris per fordring *
              </Label>
              <div>
                <Input
                  id="commission"
                  defaultValue={this.state.commission}
                  onChange={ event => {
                    this.setState({ commission: event.target.value.replace(/,/g, ".") })
                    this.isInputValid(event.target.value.replace(/,/g, "."), "Kjøpsprovisjon pr. fordring");
                  }}
                />
                <span>%</span>
              {validationErrors.filter(el => el.fieldName === "Kjøpsprovisjon pr. fordring").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Kjøpsprovisjon pr. fordring")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }
              </div>
              <Label for="limitCommissionOfCreditLimit">
                Årlig provisjon på kredittrammen *
              </Label>
              <div>
                <Input
                  id="limitCommissionOfCreditLimit"
                  defaultValue={this.state.limitCommissionOfCreditLimit}
                  onChange={ event => {
                    this.setState({ limitCommissionOfCreditLimit: event.target.value.replace(/,/g, ".") })
                    this.isInputValid(event.target.value.replace(/,/g, "."), "Årlig provisjon på kredittrammen");
                }}
                />
                <span>%</span>
                {validationErrors.filter(el => el.fieldName === "Årlig provisjon på kredittrammen").length > 0 ?
              <ToolTip href="#">
                <ToolTipText>{validationErrors.filter(el => el.fieldName === "Årlig provisjon på kredittrammen")[0].errorMessage}</ToolTipText>
              </ToolTip> : null }
              </div>
            </Group>
          </DoubleFlexCol>
          <form onSubmit={e => this.handleSubmission(e)} style={{ marginTop: 15 }}>
          <Title>Beslutning</Title>
            <CheckboxContainer onClick={() => this.setState({ approved: true })}>
              <Checkbox checked={this.state.approved} />
              <p style={{ marginLeft: 5, fontSize: 14, color: "#000040" }}>Godkjenn lånesøknad</p>
            </CheckboxContainer>
            <CheckboxContainer onClick={() => this.setState({ approved: false })}>
              <Checkbox checked={!this.state.approved} />
              <p style={{ marginLeft: 5, fontSize: 14, color: "#000040" }}>Avslå lånesøknad</p>
            </CheckboxContainer>
            <Group>
            <label for="review-comment">Kommentar til manuell behandling:</label>
            <br/>
            <div/>
            <textarea
              style={{ resize: "vertical", width: "85%" }}
              id="review-comment"
              defaultValue={this.state.comment}
              rows="6"
              onChange={event => this.setState({ comment: event.target.value })}
            />
            </Group>
          </form>
        </LeftContent>
        <RightContent>
          { this.state.creditEvaluationFailed && (
              <InfoText>
                Kredittevalueringen feilet og saken har derfor kommet til manuell behandling.
              </InfoText>
            )
          }
          <Group style={{ color: "#767676", marginLeft: "5%", position: "relative"}}>
            <p style={{ fontSize: 16, marginTop: 15, marginBottom: 5 }}>{ this.state.company.name }</p>
            <FlexRow style={{ fontSize: 14, display: "flex" }}>
              <p style={{ display: "inline", float: "left", marginRight: 64 }}>Org.nr:</p>
              <p style={{ display: "inline", float: "left" }}>{ this.state.company.orgNo }</p>
            </FlexRow>
            <FlexRow style={{ fontSize: 14 }}>
              <p style={{ display: "inline", float: "left", marginRight: 55 }}>Bransje:</p>
              <p style={{ display: "inline", float: "left" }}>{this.state.business}</p>
            </FlexRow>

          </Group>
          <Filler></Filler>
          <ReminderText>
            Felter markert med <b>*</b> må fylles ut.
            { validationErrors && validationErrors.length > 0 && (
                <Errors>
                  Fiks valideringsfeil i skjema
                  <ToolTip>
                    <ToolTipText>
                      Feil i følgende felt:
                      <ul style={{overflow: "hidden", overflowY: "scroll", maxHeight: "150px"}}>
                        {validationErrors.map(x => <li>{x.fieldName}</li>)}
                      </ul>
                      </ToolTipText>
                  </ToolTip>
                </Errors>
              )
            }
          </ReminderText>
          <ButtonArea>
            <SecondaryButton
              style={{ margin: "0 11%"}}
              disabled={canSaveOrComplete}
              onClick={() => this.handleSave()}
              color="white">
                Lagre og lukk
            </SecondaryButton>
            <PrimaryProgressButton
              type="submit"
              onClick={() => this.handleComplete()}
              disabled={canSaveOrComplete}
              isLoading={isSubmitting}
            >
              Fullfør
            </PrimaryProgressButton>
            </ButtonArea>
        </RightContent>
      </ScreenContainer>
    );
  }
}

const borderStyle = `1px solid ${Colors.Grey3}`;

const ToolTipText = styled.span`
  visibility: hidden;
  width: 12vw;
  background-color: rgb(14 14 14 / 90%);
  font-size: 90%;
  color: white;
  text-align: center;
  padding: 25px 20px;
  border-radius: 6px;
  position: fixed;
  font-weight: normal;
  z-index: 10;

  &:hover {
    visibility: visible;
  }
`
//Info Popups on Hover
const ToolTip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 2%;

  &:before{
    color: white;
    content: "!";
    display: inline-block;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 1.8ex;
    height: 1.8ex;
    font-size: 1.4ex;
    line-height: 1.8ex;
    border-radius: 1.2ex;
    margin-right: 4px;
    padding: 1px;

    background: #e23636;
    border: 1px solid #e23636;
    text-decoration: none;
  }

  &:hover:before {
    color: white;
    background: black;
    border-color: white;
    text-decoration: none;
  }

  &:hover {
    ${ToolTipText} {
      visibility: visible;
    }
  }
`

const ReminderText = styled.div`
  left: auto;
  margin-left: 5px;
  padding: 10px;
`
const InfoText = styled.div`
  left: auto;
  margin: 10px;
  padding: 10px;
  color: #FFB968;
  background-color: #FFEFDC;
  border-radius: 4px;
`

const Errors = styled.div`
  color: #e23636;
  bottom: 40px;
  width: 100%;
  left: auto;

  &:hover {
    ${ToolTip} {
      color: #e23636;
      text-decoration: none;
      border-color: white;
    }
  }
`;

const ContextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 400px;
`;

const ButtonArea = styled(ContextArea)`
  width: 100%;
  flex-direction: row;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: ${borderStyle};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
`;

const Input = styled.input`
  transition: all 0.2s ease-out;
  height: 16px;
  width: 40%;
  border-radius: 4px;
  border: 2px solid #eee;
  outline-color: #0200a0;
  margin: 5px 4px 10px 0;
  font-size: 14px;
  color: #555;
  padding: 5px;
  resize: none;
  overflow: auto;

  &:focus {
    outline: none !important;
    border: 2px solid #0200a0;
  }
`;
const Filler = styled.div`
  height: 100%;
`;

const Label = styled.label`
  display: flex;
  color: black;
  font-weight: 500;
`;

const ScreenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const LeftContent = styled.div`
  overflow: auto;
  padding: 20px 50px;
  width: 100%;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  min-width: 28%;
  border-left: ${borderStyle};
  padding: 20px 0px;
`;

const Group = styled.div`
  :not(:last-of-type) {
    border-bottom: none;
  }
`;

const Title = styled.div`
  font-size: 24px;
  color: black;
  margin: 25px 0 25px 0;
`;


const DoubleFlexCol = styled.div`
  display: flex;
  flex-direction: col;
  div:first-child{
      width: 50%;
      p:first-child{
      min-width: 35%;
    }
  }
  div:nth-child(2){
    width: 50%;
    p:first-child{
      min-width: 35%;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

