const validateMod11 = (input) => {
    if (!input) {
      return false;
    }
    let controlNumber = 2,
      sumForMod = 0;
  
    for (let i = input.length - 2; i >= 0; --i) {
      sumForMod += input.charAt(i) * controlNumber;
      if (++controlNumber > 7) controlNumber = 2;
    }
    const result = (11 - (sumForMod % 11)) % 11;
    return parseInt(input.charAt(input.length - 1), 10) === result;
  };
  
  const validateAccountNumber = (value) => {
    return value && value.length === 11 && validateMod11(value);
  };

  export default validateAccountNumber;