import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Colors, Headings, FontStyles } from "@flow/style";
import { PrimaryProgressButton, SecondaryButton } from "@flow/buttons";
import { Checkbox } from "@flow/forms";
import accountNumberValidator from "../util/accountNumberValidator";
import { ScreenContainer, LeftContent, RightContent, GroupPadded, GroupPaddedGrow, Box } from "./TaskScreenStyle";

import ContextGroup from "./ContextGroup";

const groupPadding = "20px";

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${groupPadding};
`;

const FormGroup = styled.div`
  padding: 10px 0px 0px 0px;
`;

const Form = styled.form`
  padding-top: 20px;
`;

const inputStyle = css`
  border: 1px solid ${Colors.Sea};
  box-sizing: border-box;
  border-radius: 4px;
  ${FontStyles.Normal};
  padding: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  ${inputStyle};
`;

const InputLabel = styled.label`
  input {
      margin-left: 10px;
      ${inputStyle};
  }
`;

const Errors = styled.div`
  padding-top: 30px;
  p {
      color: ${Colors.Ferrari};
  }
`;

class AccountNumberCheckScreen extends Component {
  constructor(props) {
    super(props);

    const { task = {} } = this.props;
    const { data = {} } = task;
    const { comment, accountNumber, approved } = data;

    this.state = {
        isSubmitting: false,
        validationErrors: [],
        accountNumber: accountNumber || "",
        comment,
        approved
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.onAccountNumberChange = this.onAccountNumberChange.bind(this);
    this.onApprovedChange = this.onApprovedChange.bind(this);
    this.onCommentBlur = this.onCommentBlur.bind(this);
  }

  onApprovedChange = (e) => {
    const { checked } = e.target;
    this.setState({ approved: checked });
  }

  onAccountNumberChange = (e) => {
    const { value } = e.target;
    this.setState({ accountNumber: value });
  }

  onCommentBlur = (e) => {
    const { value } = e.target;
    this.setState({ comment: value});
  };

  handleSave() {
    const { approved, accountNumber, comment } = this.state;
    const { task, onSave, close, updateCase } = this.props;

    this.setState({ isSubmitting: true });

    const formData = {
      approved: approved,
      accountNumber: accountNumber,
      comment: comment
    };

    onSave(task.taskId, formData,
      () => {
        updateCase();
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
        console.error("Could not save task");
        this.setState({ isSubmitting: false });
      }
    );
  }

  validateForm() {
    const { approved, accountNumber, comment } = this.state;
    const { t } = this.props;

    const errors = {};
    if (!comment || comment.trim().length <= 0) {
        errors.comment = t("Please provide a comment");
    }
    if (!approved) {
      errors.approved = t("Please verify that account number have been checked");
    }
    if (accountNumber.length > 0) {
      if (!accountNumberValidator(accountNumber)) {
        errors.accountNumber = t("New account number is not valid");
      }
    }

    const errorsAsArray = Object.keys(errors).map(e => {
        return errors[e];
    });

    if (errorsAsArray.length > 0) {
        this.setState({ validationErrors: errorsAsArray, isSubmitting: false });
        return false;
    }
    return true;
  }

  handleComplete() {
    const { approved, accountNumber, comment } = this.state;
    const { task, onComplete, close, updateCase } = this.props;

    this.setState({ isSubmitting: true });

    const isFormValid = this.validateForm();
    if (!isFormValid) {
        return;
    }

    const formData = {
      approved: approved,
      accountNumber: accountNumber,
      comment: comment
    };

    onComplete(task.taskId, formData,
      () => {
        updateCase();
        close();
        this.setState({ isSubmitting: false });
      },
      () => {
          console.error("Could not complete task");
          this.setState({ isSubmitting: false });
        }
    );
  }

  render() {
    const { isSubmitting, validationErrors, comment, accountNumber, approved } = this.state;
    const { task, t } = this.props;
    const { context } = task;
    const { account, company, karCheckResult } = context;
    const { account: checkedAccount = "-", identificationNumber = "-", newAccountNumber = "", result = "-", resultText = "-" } = karCheckResult;

    const companyInfo = { ...company };
    delete companyInfo.name;
  
    return (
      <ScreenContainer>
        <LeftContent>
          <Box style={{backgroundColor: Colors.SeaLightest}}>
            <Headings.H2>{t("Account number provided by customer")}:</Headings.H2>
            <p>{account.accountNumber}</p>
          </Box>
          <Box style={{backgroundColor: Colors.SeaLightest}}>
            <Headings.H2>{t("Result of check in KAR-register")}:</Headings.H2>
            <p>{`Kontonummer sjekket: ${checkedAccount}`}</p>
            <p>{`Organisasjonsnummer sjekket: ${identificationNumber}`}</p>
            {newAccountNumber !== "" && <p>{`Nytt kontonummer: ${newAccountNumber}`}</p>}
            <p>{`Eies konto av kunde: ${result}`}</p>
            <p>{`Beskrivelse: ${resultText}`}</p>
          </Box>
          <Form>
            <FormGroup>
              <InputLabel htmlFor="newAccountNumber">
                {t("Provide new account number")}:
                <input
                  key="newAccountNumber"
                  type="text"
                  name="newAccountNumber"
                  defaultValue={accountNumber}
                  onChange={this.onAccountNumberChange}
                />
              </InputLabel>
            </FormGroup>
            <FormGroup>
              <TextArea
                name="comment"
                rows="5"
                placeholder={t("Comment")}
                defaultValue={comment}
                onBlur={this.onCommentBlur}
              />
            </FormGroup>
            <FormGroup>
              <Checkbox
                id="approved"
                name="approved"
                label={t("Account number is verified")}
                onChange={this.onApprovedChange}
                value={!!approved}
                checked={!!approved}
              />
            </FormGroup>
            { validationErrors && validationErrors.length > 0 && (
              <Errors>
                  { validationErrors.map(x => {
                      return <p key={uuidv4()}>{x}</p>;
                    })
                  }
              </Errors>
              )
            }
          </Form>
        </LeftContent>
        <RightContent>
          <GroupPaddedGrow>
            <ContextGroup title={company.name} context={companyInfo} />
         </GroupPaddedGrow>
          <GroupPadded>
              <ButtonGroup>
                <SecondaryButton onClick={() => this.handleSave()} disabled={isSubmitting}>
                    {t("Save and close")}
                </SecondaryButton>
                <PrimaryProgressButton
                    type="submit"
                    onClick={() => this.handleComplete()}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                >
                    {t("Complete")}
                </PrimaryProgressButton>
              </ButtonGroup>
          </GroupPadded>
        </RightContent>
      </ScreenContainer>
    );
  }
}

AccountNumberCheckScreen.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onComplete: PropTypes.func,
  onSave: PropTypes.func,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

AccountNumberCheckScreen.defaultProps = {
  onComplete: () => { },
  onSave: () => { },
  close: () => { },
  updateCase: () => { }
};

export default withTranslation("inbox")(AccountNumberCheckScreen);